<template>
  <Dialog :visible="containerVisible" :style="{width: '600px'}" :header="$t('address.formEdit')" :modal="true" class="p-fluid" @update:visible="updateVisible">
    <div class="grid">
      <label for="contactName" class="mb-2 md:col-3 md:mb-0">{{$t('address.contactName')}}</label>
      <form-field class="md:col-9"
        :message="errors.contactName">
        <InputText ref="contactName" id="contactName" type="text" v-model="currentEntity.contactName" :required="true" autofocus/>
      </form-field>
    </div>
    <div class="grid">
      <label for="contactPhone" class="mb-2 md:col-3 md:mb-0">{{$t('address.contactPhone')}}</label>
      <form-field class="md:col-9"
        :message="errors.contactPhone">
        <InputText ref="contactPhone" id="contactPhone" type="text" v-model="currentEntity.contactPhone" :required="true" autofocus/>
      </form-field>
    </div>
    <div class="grid">
      <label for="contactEmail" class="mb-2 md:col-3 md:mb-0">{{$t('address.contactEmail')}}</label>
      <form-field class="md:col-9"
        :message="errors.contactEmail">
        <InputText ref="contactEmail" id="contactEmail" type="text" v-model="currentEntity.contactEmail" :required="false" autofocus/>
      </form-field>
    </div>
    <!-- For sender address in US -->
    <div v-if="country=='us'">
      <div class="grid">
        <label for="state" class="mb-2 md:col-3 md:mb-0">{{$t('address.state')}}</label>
        <form-field class="md:col-9"
          :message="errors.state">
          <AutoCompleteValue ref="state" v-model="currentEntity.state" :options="usStates" :required="true" @item-select="onSelectState"/>
        </form-field>
      </div>
      <div class="grid">
        <label for="county" class="mb-2 md:col-3 md:mb-0">{{$t('address.county')}}</label>
        <form-field class="md:col-9"
          :message="errors.county">
          <AutoCompleteValue ref="county" v-model="currentEntity.county" :options="usCounties" :required="true" @item-select="onSelectCounty"/>
        </form-field>
      </div>
      <div class="grid">
        <label for="city" class="mb-2 md:col-3 md:mb-0">{{$t('address.city')}}</label>
        <form-field class="md:col-9"
          :message="errors.city">
          <AutoCompleteValue ref="city" v-model="currentEntity.city" :options="usCities" :required="true"/>
        </form-field>
      </div>
    </div>
    <!-- For recipient address in VN -->
    <div v-if="country=='vn'">
      <div class="grid">
        <label for="province" class="mb-2 md:col-3 md:mb-0">{{$t('address.province')}}</label>
        <form-field class="md:col-9"
          :message="errors.province">
          <AutoCompleteValue ref="province" v-model="currentEntity.province" :options="vnProvinces" :required="true" @item-select="onSelectProvince"/>
        </form-field>
      </div>
      <div class="grid">
        <label for="district" class="mb-2 md:col-3 md:mb-0">{{$t('address.district')}}</label>
        <form-field class="md:col-9"
          :message="errors.district">
          <AutoCompleteValue ref="county" v-model="currentEntity.district" :options="vnDistricts" :required="true" @item-select="onSelectDistrict"/>
        </form-field>
      </div>
      <div class="grid">
        <label for="ward" class="mb-2 md:col-3 md:mb-0">{{$t('address.ward')}}</label>
        <form-field class="md:col-9"
          :message="errors.ward">
          <AutoCompleteValue ref="ward" v-model="currentEntity.ward" :options="vnWards" :required="true"/>
        </form-field>
      </div>
    </div>
    <div class="grid">
      <label for="address" class="mb-2 md:col-3 md:mb-0">{{$t('address.type')}}</label>
      <form-field class="md:col-9">
        <Dropdown ref="type" v-model="currentEntity.type" :options="types" optionValue="value" optionLabel="label" :placeholder="$t('address.type')" />
      </form-field>
    </div>
    <div class="grid">
      <label for="detail" class="mb-2 md:col-3 md:mb-0">{{$t('address.detail')}}</label>
      <div class="md:col-9">
        <Textarea ref="detail" id="detail" v-model="currentEntity.detail" required="false" rows="2" cols="20" />
      </div>
    </div>
    <div class="grid">
      <label for="description" class="mb-2 md:col-3 md:mb-0">{{$t('address.description')}}</label>
      <div class="md:col-9">
        <Textarea ref="description" id="description" v-model="currentEntity.description" required="false" rows="3" cols="20" />
      </div>
    </div>
    <template #footer>
      <Button :label="$t('button.cancel')" icon="pi pi-times" class="p-button-text" @click="onCancel"/>
      <Button :label="$t('button.save')" icon="pi pi-check" class="p-button-text" @click="onSave" />
    </template>
  </Dialog>
</template>

<script>
import ConfigService from '@/service/ConfigService'
import AutoCompleteValue from '@/components/form/AutoCompleteValue'
import FormField from '@/components/FormField'
import form from '@/mixins/form'
import geoinfo from '@/mixins/geoinfo'
//import { useForm, useField } from 'vee-validate';
export default {
  components: {AutoCompleteValue, FormField},
  name:'form-partner',
  mixins: [form, geoinfo],
  props: {
    entity: {
      type: Object,
      default() {
        // default function receives the raw props object as argument
        return {  }
      }
    },
    country: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits:['cancel','save','hide'],
  data() {
    return {
      submitted: false,
      currentEntity: this.entity,
      containerVisible: this.visible,
      partnerTypes: [],
      usStates: [],
      usCounties: [],
      usCities: [],
      vnProvinces: [],
      vnDistricts: [],
      vnWards: [],
      types: [{
        value: 'real',
        label: this.$t('address.type_real')
      }, {
        value: 'fake',
        label: this.$t('address.type_fake')
      }],
      selectedState: null,
      selectedProvince: null,
      errors: {},
    }
  },
  methods: {
    onSelectState(event) {
      this._setState(event.value);
    },
    onSelectCounty(event) {
      this._setCounty(this.currentEntity.state, event.value);
    },
    onSelectProvince(event) {
      this._setProvince(event.value);
    },
    onSelectDistrict(event) {
      this._setDistrict(this.currentEntity.province, event.value);
    },
    onCancel() {
      this.containerVisible = false;
      this.currentEntity = {};
      this.$emit('cancel');
    },
    onSave() {
      if(!this.submitted) {
        this.errors = this.validate(this.currentEntity, 'address');
        if(Object.keys(this.errors).length == 0) {
          this.submitted = true;
          this.$emit('save', this.currentEntity);
        }
      }
    },
    updateVisible(visible) {
      this.containerVisible = visible;
      this.$emit('hide')
    }
  },
  mounted() {
    this.loadGeoInfos([this.country]);
    this.partnerTypes = ConfigService.getPartnerTypes().map((type) => {
      return {
        label: this.$t('configs.partner_type.' + type),
        value: type
      }
    });
    this.selectedPartnerType = ConfigService.getSelectedItem(this.partnerTypes, this.currentEntity, "partnerType");
  },
  updated() {
    this.currentEntity = this.entity;
    this.selectedPartnerType = ConfigService.getSelectedItem(this.partnerTypes, this.currentEntity, "partnerType");
    this.containerVisible = this.visible;
  },
}
</script>
