import GeoInfoService from '@/service/GeoInfoService'
const geoinfo = {
  methods: {
    loadGeoInfos(countries) {
      GeoInfoService.loadFirstLevel(countries).then(res => {
        this.usStates = res['us'];
        this.vnProvinces = res['vn'];
      });
      if (Array.isArray(countries) && countries.length == 1) {
        GeoInfoService.getByCountry(countries[0]).then(res => {
          if(countries[0] == 'us') {
            this.usGeoInfos = res;
            if (this.selectedState) {
              this._setSenderState(this.selectedState);
            }
          } else if (countries[0] == 'vn') {
            this.vnGeoInfos = res;
            if (this.selectedProvince) {
              this._setPovince(this.selectedProvince);
            }
          }
        });
      }
    },
    _setState(value) {
      let selectedState;
      if(Array.isArray(this.usStates)) {
        let lcValue = value.trim().toLowerCase();
        for(let i = 0; i < this.usStates.length; i++) {
          if (this.usStates[i].toLowerCase().indexOf(lcValue) >= 0) {
            selectedState = this.usStates[i];
            break;
          }
        }
      }
      if (this.usGeoInfos && selectedState) {
        const counties = this.usGeoInfos[selectedState];
        if(typeof counties == 'object') {
          this.usCounties = Object.keys(counties);
        }
      }
    },
    _setCounty(state, county) {
      let selectedCounty;
      if(Array.isArray(this.usCounties)) {
        let lcValue = county.trim().toLowerCase();
        for(let i = 0; i < this.usCounties.length; i++) {
          if (this.usCounties[i].toLowerCase().indexOf(lcValue) >= 0) {
            selectedCounty = this.usCounties[i];
            break;
          }
        }
      }
      if (this.usGeoInfos && state && selectedCounty) {
        const counties = this.usGeoInfos[state];
        const cities = counties[selectedCounty];
        if (typeof cities == 'object') {
          this.usCities = Object.keys(cities);
        }
      }
    },
    _setProvince(value) {
      let selectedProvince;
      if(typeof value == 'string' && Array.isArray(this.vnProvinces)) {
        let lcValue = value.trim().toLowerCase();
        for(let i = 0; i < this.vnProvinces.length; i++) {
          if (this.vnProvinces[i].toLowerCase().indexOf(lcValue) >= 0) {
            selectedProvince = this.vnProvinces[i];
            break;
          }
        }
      }
      if (this.vnGeoInfos && selectedProvince) {
        const districts = this.vnGeoInfos[selectedProvince];
        if (districts) {
          this.vnDistricts = Object.keys(districts);
        } else {
          this.vnDistricts = [];
        }
      }
    },
    _setDistrict(province, value) {
      let selectedDistrict;
      if(typeof value == 'string' && Array.isArray(this.vnDistricts)) {
        let lcValue = value.trim().toLowerCase();
        for(let i = 0; i < this.vnDistricts.length; i++) {
          if (this.vnDistricts[i].toLowerCase().indexOf(lcValue) >= 0) {
            selectedDistrict = this.vnDistricts[i];
            break;
          }
        }
      }
      if (this.vnGeoInfos && selectedDistrict) {
        let districts = this.vnGeoInfos[province];
        this.vnWards = Object.keys(districts[selectedDistrict]);
      }
    },
  }
}

export default geoinfo;
