<template>
	<div class="grid">
		<Toast/>
		<CrudTable ref="addresses" :entities="entities"
			:meta="metaTable"
			@paging="onPaging"
			@tableAction="onTableAction"
			@itemAction="onItemAction"
			@row-edit-complete="onRowEditComplete"/>
		<DialogFormAddress :entity="currentEntity" :visible="entityDialog"
				:country="country"
				@hide="entityDialog=false"
				@cancel="entityDialog=false"
				@save="saveEntity($event)"/>

		<dialog-confirm :visible="deleteEntityDialog"
			:header="$t('user.confirm_delete')"
			content = "Dialog content"
			@hide="deleteEntityDialog = false"
			@reject="deleteEntityDialog = false" @accept="deleteEntity"
		>
			<template #content>
				<div class="flex align-items-center justify-content-center">
					<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
					<span v-if="currentEntity">Are you sure you want to delete <b>{{currentEntity.email}}</b>?</span>
				</div>
			</template>
		</dialog-confirm>
	</div>

</template>

<script>
import AddressService from '@/service/AddressService'
import DialogConfirm from '@/pages/common/DialogConfirm'
import CrudTable from '@/pages/common/CrudTable'
import DialogFormAddress from './DialogFormAddress'
import Consts from '@/utils/consts'
export default {
	components: {CrudTable, DialogFormAddress, DialogConfirm},
  props: {
    country: {
      type: String,
      default: ''
    },
    globalFilterFields: {
      type: Array,
      default() {
        return [];
      }
    },
    columns: {
      type: Array,
      default() {
        return [];
      }
    }
  },
	data() {
		return {
			entities: [],
			selectedEntity: null,
			entityDialog: false,
			deleteEntityDialog: false,
			deleteEntitiesDialog: false,
			currentEntity: {},
			selectedEntities: null,
			totalRecords:10,
			metaTable: {
				entityName: "address",
				expander: false,
				searchable: true,
				rowEditor: false,
				editMode:"row",
				lazy: true,
				paginator:{
					enable: true,
					rows: 10,
					totalRecords:0,
					rowsPerPageOptions: Consts.ROW_PER_PAGE_OPTIONS,
					currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} addresses",
				},
        filter: {
          filterDisplay: "menu",
          globalFilterFields: this.globalFilterFields
        },
				columns: this.columns,
				tableActions: [
          {
            name: "filterclear",
            icon: "pi pi-filter-slash",
            style: "p-button-outlined mr-2"
          },
					{
						name: "create",
						icon: "pi pi-plus",
						style: "p-button mr-2"
					}
				],
				itemActions: [
					{
						name: "edit",
						icon: "pi pi-pencil",
						style: "p-button-rounded p-button-success mr-2",
						condition: "mode != 'edit'"
					},
					{
						name: "save",
						icon: "pi pi-save",
						style: "p-button-rounded p-button-success mr-2",
						condition: "mode == 'edit'"
					},
					{
						name: "delete",
						icon: "pi pi-trash",
						style: "p-button-rounded p-button-warning mt-2",
						condition: "mode != 'edit'"
					}
				]
			}
		}
	},
	mounted() {
    let options = {
      page:0,
      size:this.metaTable.paginator.rows || 10,
      country: this.country
    };
		AddressService.getAll(options).then(data => {
			this.entities = data.content;
			this.metaTable.paginator.totalRecords = data.totalElements;
		});
	},
	methods: {
		onTableAction(sAction) {
			if(sAction == 'create') {
				this.currentEntity = {};
				this.entityDialog = true;
			}
		},
		onItemAction(sAction, entity, index) {
			if (sAction =='save') {
				if (entity.id) {
					AddressService.update(entity).then(response => {
						this.entities[index] = response.data
					});
				} else {
					let data = {...entity};
					AddressService.create(data).then(response => {
						this.entities[index] = {mode:'edit'};
						this.entities.push(response.data);
					});
				}
			} else if (sAction == 'edit') {
				this.currentEntity = entity;
				this.entityDialog = true;
			} else if (sAction == 'delete') {
				AddressService.delete(entity.id).then(() => {
					this.entities.splice(index, 1);
				});
			}
		},
		onPaging(pageEvent) {
			AddressService.getAll({page:pageEvent.page,size:pageEvent.rows}).then(data => {
				this.entities = data.content;
				this.totalRecords = data.totalElements;
				this.metaTable.paginator.totalRecords = data.totalElements;
			});
		},
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			return;
		},
		openNew() {
			this.currentEntity = {};
			this.entityDialog = true;
		},
		hideDialog() {
			this.entityDialog = false;
			this.submitted = false;
		},
		saveEntity(entity) {
			if (entity.id) {
				this.entities[this.findIndexById(entity.id)] = entity;
					AddressService.update(entity.id, entity).then((response) => {
						console.log(response);
						this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('user.updated'), life: 3000});
						this.currentEntity = response.data;
						this.entityDialog = false;
					})
			} else {
				AddressService.create(entity).then((response) => {
					this.currentEntity = response.data;
					this.entities.push(response.data);
					this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('user.created'), life: 3000});
					this.entityDialog = false;
				})
			}
			this.selectedEntity = {};
		},
		editEntity(entity) {
			this.currentEntity = {...entity};
			this.entityDialog = true;
		},
		openConfirmDialog() {
        this.$confirm.require({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                //callback to execute when user confirms the action
            },
            reject: () => {}
        });
    },
		confirmDeleteEntity(entity) {
			this.currentEntity = entity;
			this.deleteEntityDialog = true;
		},
		deleteEntity() {
			this.entities = this.entities.filter(val => val.id !== this.currentEntity.id);
			this.deleteEntityDialog = false;
			this.currentEntity = {};
			this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('user.deleted'), life: 3000});
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.entities.length; i++) {
				if (this.entities[i].id === id) {
					index = i;
					break;
				}
			}
			return index;
		},
		onRowEditComplete() {
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteEntitiesDialog = true;
		},
		deleteSelectedEntities() {
			this.entities = this.entities.filter(val => !this.selectedEntities.includes(val));
			this.deleteEntitiesDialog = false;
			this.selectedEntities = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('user.multiple_deleted'), life: 3000});
		}
	}
}
</script>

<style scoped lang="scss">
@import '@/assets/demo/badges.scss';
</style>
