<template>
	<TabView>
		<TabPanel :header="$t('address.sender')">
			<AddressListComp country="us" :globalFilterFields="senderGlobalFilterFields" :columns="senderColumns"/>
		</TabPanel>
		<TabPanel :header="$t('address.recipient')">
			<AddressListComp country="vn" :globalFilterFields="recipientGlobalFilterFields" :columns="recipientColumns"/>
		</TabPanel>
	</TabView>
</template>

<script>
import AddressListComp from './AddressListComp'
export default {
	components: {AddressListComp},
	data() {
		return {
			senderGlobalFilterFields: ['contactName','contactPhone'],
			recipientGlobalFilterFields: ['contactName', 'contactPhone'],
			senderColumns: [
				{
					name: "contactName",
					headerStyle: "width:20%; min-width:10rem;",
					filter: true
				},
				{
					name: "contactPhone",
					headerStyle: "width:10%; min-width:10rem;",
					filter: true
				},
				{
					name: "state",
					headerStyle: "width:12%; min-width:10rem;",
					filter: true
				},
				{
					name: "county",
					headerStyle: "width:12%; min-width:10rem;",
					filter: true
				},
				{
					name: "city",
					headerStyle: "width:12%; min-width:10rem;",
					filter: true
				},
				{
					name: "detail",
					headerStyle: "width:30%; min-width:10rem;",
					filter: true
				},
				// {
				// 	name: "type",
				// 	headerStyle: "width:10%; min-width:10rem;",
				// 	filter: true
				// },
				{
					name: "partner",
					headerStyle: "width:10%; min-width:10rem;",
					filter: true
				},
				{
					name: "description",
					headerStyle: "width:30%; min-width:10rem;",
					filter: true
				}
			],
			recipientColumns: [
				{
					name: "contactName",
					headerStyle: "width:20%; min-width:10rem;",
					filter: true
				},
				{
					name: "contactPhone",
					headerStyle: "width:10%; min-width:10rem;",
					filter: true
				},
				{
					name: "province",
					headerStyle: "width:12%; min-width:10rem;",
					filter: true
				},
				{
					name: "district",
					headerStyle: "width:12%; min-width:10rem;",
					filter: true
				},
				{
					name: "ward",
					headerStyle: "width:12%; min-width:10rem;",
					filter: true
				},
				{
					name: "detail",
					headerStyle: "width:30%; min-width:10rem;",
					filter: true
				},
				{
					name: "partner",
					headerStyle: "width:10%; min-width:10rem;",
					filter: true
				},
				// {
				// 	name: "type",
				// 	headerStyle: "width:10%; min-width:10rem;",
				// 	filter: true
				// },
				{
					name: "description",
					headerStyle: "width:30%; min-width:10rem;",
					filter: true
				}
			]
		}
	},
	created() {

	},
	mounted() {

	},
	methods: {

	}
}
</script>
